import chooseProductDictionary from '@features/choose-product/i18n/ru/choose-product.dic.json';
import balanceDictionary from '@entities/balance/i18n/ru/balance.dic.json';
import productsDictionary from '@entities/product-list/i18n/ru/products.dic.json';

export const mainPage = {
  CHOOSE_PRODUCT: chooseProductDictionary,
  PRODUCTS: productsDictionary,
  BALANCE: balanceDictionary,
};

export const mainPageNamespaces = ['CHOOSE_PRODUCT', 'PRODUCTS', 'BALANCE'];
