import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  MAIN,
  ACTS,
  CONTRACTS,
  AGENT_CONTRACT,
  NO_AGENT_CONTRACT,
  CONFIRM_CONTRACT,
  CREATE_SUBSCRIPTION,
  PROFILE,
} from '@shared/constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@src/shared';

const ActsListPage = lazy(() => import('./acts-list'));
const ContractsListPage = lazy(() => import('./contracts-list'));
const MainPage = lazy(() => import('./main'));
const AgentContractPage = lazy(() => import('./agent-contract'));
const NoAgentContractPage = lazy(() => import('./no-agent-contract'));
const ConfirmContractPage = lazy(() => import('./confirm-contract'));
const CreateSubscriptionPage = lazy(() => import('./create-subscription'));
const ProfilePage = lazy(() => import('./profile'));

const Routing = observer(() => {
  const {
    MainStore: {
      agentContractStore: { profile },
      agentUserStore: { confirmToken },
    },
  } = useStores();

  return (
    <Routes>
      <Route path={MAIN} element={<MainPage />} />
      <Route path={ACTS} element={<ActsListPage />} />
      <Route path={CONTRACTS} element={<ContractsListPage />} />
      <Route path={AGENT_CONTRACT} element={<AgentContractPage />} />
      <Route path={NO_AGENT_CONTRACT} element={<NoAgentContractPage />} />
      <Route path={CREATE_SUBSCRIPTION} element={<CreateSubscriptionPage />} />
      <Route path={PROFILE} element={<ProfilePage />} />
      {!!profile?.phone && !!confirmToken && (
        <Route path={CONFIRM_CONTRACT} element={<ConfirmContractPage />} />
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default Routing;
