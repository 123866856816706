import { media, styled, typography } from '@pulse-web-ui/theme';
import { Link } from 'react-router-dom';
import { css } from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  background: ${({ theme }) => theme.colors.surface.primary};
  box-sizing: border-box;
  padding: 0 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  ${media.desktop} {
    padding: 0 24px;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PulseLogo = styled.img`
  margin-top: 4px;
  width: 69px;
  height: 25px;
`;

export const RgsLogo = styled.img`
  width: 103px;
  height: 15px;
`;

export const Separator = styled.div`
  height: 14px;
  width: 1px;
  background: ${({ theme }) => theme.colors.icon.quaternary};
`;

export const HeaderButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const HeaderNav = styled.nav`
  width: 100%;
  background: ${({ theme }) => theme.colors.surface.background};
  position: fixed;
  left: 0;
  top: 72px;
  height: calc(100vh - 72px);
  z-index: 1000;
  box-sizing: border-box;
  padding-top: 1px;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-bottom: 8px;
`;

const navItemStyles = css`
  ${typography.medium16};
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  height: 56px;
  width: 100%;
  background: ${({ theme }) => theme.colors.surface.primary};
  box-sizing: border-box;
  padding: 0 16px;

  ${media.desktop} {
    padding: 0 24px;
  }
`;

export const NavItemLink = styled(Link)`
  ${navItemStyles}
  text-decoration: none;
`;

export const LogoutButton = styled.div`
  ${navItemStyles}
  cursor: pointer;
`;
