import { makeAutoObservable } from 'mobx';

import { Main } from '@app/store';
import { Profile } from '../types';
import {
  Address,
  BankDetails,
  PersonalDocuments,
} from '../types/agent-contract.types';

//TODO: переименовать в AgentProfileStore, объединить с AgentUserStore и удалить AgentUserStore
export class AgentContractStore {
  private mainStore: Main;
  profile?: Profile;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setPersonalDocuments = (personalDocument: PersonalDocuments) => {
    this.profile = {
      ...this.profile,
      personalDocument,
    };
  };

  setAddresses = (addressesAgents: Array<Address>) => {
    this.profile = {
      ...this.profile,
      addressesAgents,
    };
  };

  setBankDetails = (bankDetails: BankDetails) => {
    this.profile = {
      ...this.profile,
      bankDetails,
    };
  };

  updateProfile = (newProfile: Profile) => {
    this.profile = {
      ...this.profile,
      ...newProfile,
    };
  };
}
