import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox } from '@pulse-web-ui/checkbox';

import {
  AGENT_CONTRACT_AGREEMENT,
  DISTRIBUTION_PERSONAL_DATA_AGREEMENT,
  FormControlContainer,
  analyticEvents,
  useStores,
} from '@src/shared';

import { AgentContractAgreementsWrapper } from './agent-contract-agreements.styles';
import { AgentContractAgreementsProps } from '../model';
import { sendAnalyticEvent } from '@src/app/web-analytic';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export const AgentContractAgreements = observer(() => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<AgentContractAgreementsProps>();
  const {
    MainStore: {
      agentContractAgreementsStore: {
        contractConditionsAgreement,
        personalDataManagement,
        setContractConditionsAgreement,
        setPersonalDataManagement,
      },
    },
  } = useStores();

  const handleContractConditionsAgreementClick = () => {
    sendAnalyticEvent(analyticEvents.agentAgreementTermsForm);
  };

  const handlePersonalDataManagementClick = () => {
    sendAnalyticEvent(analyticEvents.agentAgreementPdnForm);
  };

  useEffect(() => {
    setValue('contractConditionsAgreement', contractConditionsAgreement);
    setValue('personalDataManagement', personalDataManagement);
  }, []);

  return (
    <AgentContractAgreementsWrapper>
      <FormControlContainer>
        <Controller
          control={control}
          name="contractConditionsAgreement"
          render={({ field: { onChange, value }, fieldState }) => (
            <Checkbox
              id="contractConditionsAgreement"
              name="contractConditionsAgreement"
              label={
                <Trans
                  values={{
                    agreementHref: AGENT_CONTRACT_AGREEMENT,
                  }}
                  components={{
                    a: <a onClick={handleContractConditionsAgreementClick} />,
                  }}
                >
                  {t(
                    'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithContractConditions'
                  )}
                </Trans>
              }
              checked={value}
              onChange={(event) => {
                onChange(event);
                setContractConditionsAgreement(event.target.checked);
              }}
              status={!!fieldState.error ? 'error' : undefined}
              message={errors.contractConditionsAgreement?.message}
            />
          )}
        />
      </FormControlContainer>
      <FormControlContainer margin="0 0 24px 0">
        <Controller
          control={control}
          name="personalDataManagement"
          render={({ field: { onChange, value }, fieldState }) => (
            <Checkbox
              id="personalDataManagement"
              name="personalDataManagement"
              label={
                <Trans
                  values={{
                    agreementHref: DISTRIBUTION_PERSONAL_DATA_AGREEMENT,
                  }}
                  components={{
                    a: <a onClick={handlePersonalDataManagementClick} />,
                  }}
                >
                  {t(
                    'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithPersonalDataManagement'
                  )}
                </Trans>
              }
              checked={value}
              onChange={(event) => {
                onChange(event);
                setPersonalDataManagement(event.target.checked);
              }}
              status={!!fieldState.error ? 'error' : undefined}
              message={errors.personalDataManagement?.message}
            />
          )}
        />
      </FormControlContainer>
    </AgentContractAgreementsWrapper>
  );
});
