export const PlatformType = 'WEB';

export enum AuthEndpoints {
  LOGIN = '/login',
}
export enum AgentEndpoints {
  AGENTS = '/api/external/agents/v1',
}

export enum DictionariesEndpoints {
  DICTIONARIES = '/api/common/v1/dictionaries',
}

export enum RequestMethods {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
}
