import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Act,
  Burger,
  Close,
  Coins,
  Exit,
  Person,
  PhoneTransparent,
  Pulse,
} from '@pulse-web-ui/icons';
import { IconButton, PROFILE } from '@src/shared';
import { useTheme } from '@pulse-web-ui/theme';
import { UserContext } from '@contexts/user-context';
import {
  HeaderButtons,
  HeaderContainer,
  HeaderNav,
  LogoLink,
  LogoutButton,
  NavItemLink,
  NavList,
  PulseLogo,
  RgsLogo,
  Separator,
} from './header.styles';
import pulseLogo from '../../assets/images/pulse-logo.svg';
import rgsLogo from '../../assets/images/rgs-logo.svg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const Header = () => {
  const { isAuthenticated } = useContext(UserContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNavDisplayed, setIsNavDisplayed] = useState(false);

  const toggleNav = () => {
    if (isNavDisplayed) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setIsNavDisplayed(!isNavDisplayed);
  };

  const closeMenu = () => setIsNavDisplayed(false);

  return (
    <>
      <HeaderContainer>
        <LogoLink to="/">
          <PulseLogo src={pulseLogo} alt="Logo" />
          <Separator />
          <RgsLogo src={rgsLogo} alt="Logo" />
        </LogoLink>
        {isAuthenticated && (
          <HeaderButtons>
            <Link to={PROFILE}>
              <IconButton>
                <Person width={18} color={theme.colors.icon.primary} />
              </IconButton>
            </Link>
            <IconButton onClick={toggleNav}>
              {isNavDisplayed ? (
                <Close width={20} color={theme.colors.icon.primary} />
              ) : (
                <Burger width={18} color={theme.colors.icon.primary} />
              )}
            </IconButton>
          </HeaderButtons>
        )}
      </HeaderContainer>
      {isNavDisplayed && (
        <HeaderNav>
          <NavList>
            <li>
              <NavItemLink to="/sales" onClick={closeMenu}>
                <IconButton>
                  <Coins width={20} color={theme.colors.icon.primary} />
                </IconButton>
                <span>{t('COMMON:navigation.sales')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink to="/acts" onClick={closeMenu}>
                <IconButton>
                  <Act width={20} color={theme.colors.icon.primary} />
                </IconButton>
                <span>{t('COMMON:navigation.acts')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink to="/about" onClick={closeMenu}>
                <IconButton>
                  <Pulse width={18} color={theme.colors.icon.primary} />
                </IconButton>
                <span>{t('COMMON:navigation.about')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink to="/contacts" onClick={closeMenu}>
                <IconButton>
                  <PhoneTransparent
                    width={19}
                    color={theme.colors.icon.primary}
                  />
                </IconButton>
                <span>{t('COMMON:navigation.contacts')}</span>
              </NavItemLink>
            </li>
          </NavList>
          <LogoutButton>
            <IconButton>
              <Exit width={18} color={theme.colors.icon.primary} />
            </IconButton>
            <span>{t('COMMON:navigation.logout')}</span>
          </LogoutButton>
        </HeaderNav>
      )}
    </>
  );
};
