import { useState, useEffect, useCallback, useMemo } from 'react';
import { redirect } from 'react-router-dom';

import { LoginRequestParams } from '@shared/types';

import {
  LocalStorageUserInfoKeys,
  getCurrentUserInfo,
  isLoggedIn,
  putAuthenticationInfoInLocalStorage,
  removeUserInfoFromLocalStorage,
} from './user-context-utils';
import { UserContext } from './user-context';
import { injectAutoLogoutActions } from './user-auto-logout';
import { useLogin } from './use-login';

interface Props {
  children: JSX.Element;
}

export const UserAuthProvider = ({ children }: Props) => {
  const [username, setUsername] = useState<string | undefined | null>();
  const [password, setPassword] = useState<string | undefined | null>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loginError, setLoginError] = useState<any>();
  const [isNeedToRedirectToMain, setIsNeedToRedirectToMain] =
    useState<boolean>(false);
  const isAuthenticated = isLoggedIn();

  const getUserName = () => {
    return username || localStorage.getItem(LocalStorageUserInfoKeys.name);
  };

  const { res, refetch, error, isFetching } = useLogin({
    username,
    password,
  });

  const handleLogout = useCallback(() => {
    removeUserInfoFromLocalStorage();
    setUsername(null);
    setPassword(null);
    setLoginError(null);
  }, []);

  const handleLogin = useCallback((params: LoginRequestParams) => {
    setUsername(params.username);
    setPassword(params.password);
  }, []);

  useEffect(() => {
    if (!!username && !!password) {
      refetch();
    }
  }, [username, password]);

  useEffect(() => {
    if (!isFetching && res) {
      putAuthenticationInfoInLocalStorage(res);
      const { name } = getCurrentUserInfo();
      setUsername(name);
      setIsNeedToRedirectToMain(true);
    }
  }, [isFetching, res]);

  useEffect(() => {
    if (isNeedToRedirectToMain) {
      redirect('/');
      setIsNeedToRedirectToMain(false);
    }
  }, [isNeedToRedirectToMain]);

  useEffect(() => {
    if (error) {
      setLoginError(error);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    injectAutoLogoutActions(handleLogout);
  }, [handleLogout]);

  const context = useMemo(
    () => ({
      handleLogin,
      handleLogout,
      isAuthenticated,
      loginError,
      username,
      getUserName,
    }),
    [
      handleLogin,
      handleLogout,
      isAuthenticated,
      loginError,
      username,
      getUserName,
    ]
  );

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
