import { common, commonNamespaces } from './common';
import { login, loginNamespaces } from './login';
import { password, passwordNamespaces } from './password';
import { registration, registrationNamespaces } from './registration';
import { agreements, agreementsNamespaces } from './agreements';
import { otp, otpNamespaces } from './otp';
import {
  passwordRecovery,
  passwordRecoveryNamespaces,
} from './password-recovery';
import {
  agentContractAlert,
  agentContractAlertNamespaces,
} from './agent-contract-alert';
import { agentAddress, agentAddressNamespaces } from './agents-address';
import {
  agentAddressForm,
  agentAddressFormNamespaces,
} from './agents-address-form';
import {
  contractSuccessAlert,
  contractSuccessAlertNamespaces,
} from './contract-success-alert';
import {
  contractCreatedForm,
  contractCreatedFormNamespaces,
} from './contract-created-form';
import {
  agentContractAgreements,
  agentContractAgreementsNamespaces,
} from './agent-contract-agreements';
import {
  showAgentContract,
  showAgentContractNamespaces,
} from './show-agent-contract';
import { agentContract, agentContractNamespaces } from './agent-contract';
import {
  personalDocuments,
  personalDocumentsNamespaces,
} from './personal-documents';
import {
  agentBankDetails,
  agentBankDetailsNamespaces,
} from './agent-bank-details';
import {
  agentBankDetailsForm,
  agentBankDetailsFormNamespaces,
} from './agent-bank-details-form';
import { registrationOtp, registrationOtpNamespaces } from './registration-otp';
import { contractOtp, contractOtpNamespaces } from './contract-otp';
import { mainPage, mainPageNamespaces } from './main-page';
import {
  subscriptionCreatedAlert,
  subscriptionCreatedAlertNamespaces,
} from './subscription-created-alert';
import {
  subscriptionErrorAlert,
  subscriptionErrorAlertNamespaces,
} from './subscription-error-alert';
import { agentProfile, agentProfileNamespaces } from './agent-profile';

export const resources = {
  ru: {
    ...common,
    ...login,
    ...password,
    ...registration,
    ...agreements,
    ...otp,
    ...passwordRecovery,
    ...agentContractAlert,
    ...agentAddress,
    ...agentAddressForm,
    ...contractSuccessAlert,
    ...contractCreatedForm,
    ...agentContractAgreements,
    ...showAgentContract,
    ...agentContract,
    ...personalDocuments,
    ...agentBankDetails,
    ...agentBankDetailsForm,
    ...registrationOtp,
    ...contractOtp,
    ...mainPage,
    ...subscriptionCreatedAlert,
    ...subscriptionErrorAlert,
    ...agentProfile,
  },
};

export const namespaces = [
  ...commonNamespaces,
  ...loginNamespaces,
  ...passwordNamespaces,
  ...registrationNamespaces,
  ...agreementsNamespaces,
  ...otpNamespaces,
  ...passwordRecoveryNamespaces,
  ...agentContractAlertNamespaces,
  ...agentAddressNamespaces,
  ...agentAddressFormNamespaces,
  ...contractSuccessAlertNamespaces,
  ...contractCreatedFormNamespaces,
  ...agentContractAgreementsNamespaces,
  ...showAgentContractNamespaces,
  ...agentContractNamespaces,
  ...personalDocumentsNamespaces,
  ...agentBankDetailsNamespaces,
  ...agentBankDetailsFormNamespaces,
  ...registrationOtpNamespaces,
  ...contractOtpNamespaces,
  ...mainPageNamespaces,
  ...subscriptionCreatedAlertNamespaces,
  ...subscriptionErrorAlertNamespaces,
  ...agentProfileNamespaces,
];
